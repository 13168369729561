const deprecatedIcons = [
    {
        newName: 'refresh',
        oldName: 'build'
    },
    {
        newName: 'cross',
        oldName: 'close-dialog'
    },
    {
        newName: 'chevron-right',
        oldName: 'collapsed'
    },
    {
        newName: 'settings',
        oldName: 'configure'
    },
    {
        newName: 'copy',
        oldName: 'copy-clipboard'
    },
    {
        newName: 'trash',
        oldName: 'delete'
    },
    {
        newName: 'detail-view',
        oldName: 'details'
    },
    {
        newName: 'arrow-left',
        oldName: 'devtools-arrow-left'
    },
    {
        newName: 'arrow-right',
        oldName: 'devtools-arrow-right'
    },
    {
        newName: 'sign-in',
        oldName: 'devtools-checkout'
    },
    {
        newName: 'import',
        oldName: 'devtools-clone'
    },
    {
        newName: 'folder-filled',
        oldName: 'devtools-folder-closed'
    },
    {
        newName: 'export',
        oldName: 'devtools-pull-request'
    },
    {
        newName: 'tag',
        oldName: 'devtools-tag'
    },
    {
        newName: 'tag',
        oldName: 'devtools-tag-small'
    },
    {
        newName: 'menu',
        oldName: 'drag-vertical'
    },
    {
        newName: 'edit-filled',
        oldName: 'edit'
    },
    {
        newName: 'edit-filled',
        oldName: 'edit-small'
    },
    {
        newName: 'chevron-up',
        oldName: 'expanded'
    },
    {
        newName: 'vid-full-screen-on',
        oldName: 'focus'
    },
    {
        newName: 'more-vertical',
        oldName: 'handle-horizontal'
    },
    {
        newName: 'question-circle',
        oldName: 'help'
    },
    {
        newName: 'home-circle',
        oldName: 'homepage'
    },
    {
        newName: 'image',
        oldName: 'image-extrasmall'
    },
    {
        newName: 'info-circle',
        oldName: 'info'
    },
    {
        newName: 'world',
        oldName: 'weblink'
    },
    {
        newName: 'add-circle',
        oldName: 'list-add'
    },
    {
        newName: 'cross-circle',
        oldName: 'list-remove'
    },
    {
        newName: 'lock-filled',
        oldName: 'locked'
    },
    {
        newName: 'lock-filled',
        oldName: 'locked-small'
    },
    {
        newName: 'document',
        oldName: 'page-blank'
    },
    {
        newName: 'document',
        oldName: 'doc'
    },
    {
        newName: 'documents',
        oldName: 'pages'
    },
    {
        newName: 'cross-circle',
        oldName: 'remove'
    },
    {
        newName: 'cross-circle',
        oldName: 'remove-label'
    },
    {
        newName: 'search',
        oldName: 'search-small'
    },
    {
        newName: 'person-circle',
        oldName: 'space-personal'
    },
    {
        newName: 'star-filled',
        oldName: 'star'
    },
    {
        newName: 'check',
        oldName: 'success'
    },
    {
        newName: 'recent',
        oldName: 'time'
    },
    {
        newName: 'vid-full-screen-off',
        oldName: 'unfocus'
    },
    {
        newName: 'unlock-filled',
        oldName: 'unlocked'
    },
    {
        newName: 'star',
        oldName: 'unstar'
    },
    {
        newName: 'watch',
        oldName: 'unwatch'
    },
    {
        newName: 'arrow-up',
        oldName: 'up'
    },
    {
        newName: 'arrow-down',
        oldName: 'down'
    },
    {
        newName: 'person',
        oldName: 'user'
    },
    {
        newName: 'watch-filled',
        oldName: 'view'
    },
    {
        newName: 'room-menu',
        oldName: 'view-list'
    },
    {
        newName: 'menu',
        oldName: 'view-table'
    },
    {
        newName: 'watch-filled',
        oldName: 'watch'
    },
    {
        newName: 'tray',
        oldName: 'workbox'
    },
    {
        newName: 'bullet-list',
        oldName: 'configure-columns'
    },
    {
        newName: 'image',
        oldName: 'file-image'
    },
    {
        newName: 'group',
        oldName: 'admin-roles'
    },
    {
        newName: 'vid-pause',
        oldName: 'pause'
    },
    {
        newName: 'refresh',
        oldName: 'refresh-small'
    },
    {
        newName: 'swap',
        oldName: 'switch-small'
    },
    {
        newName: 'arrow-down-small',
        oldName: 'arrow-down'
    },
    {
        newName: 'arrow-up-small',
        oldName: 'arrow-up'
    },
    {
        newName: 'email',
        oldName: 'email-large'
    },
    {
        newName: 'documents',
        oldName: 'pages-large'
    },
    {
        newName: 'person',
        oldName: 'user-large'
    },
    {
        newName: 'documents',
        oldName: 'bp-decisions'
    },
    {
        newName: 'documents',
        oldName: 'bp-default'
    },
    {
        newName: 'documents',
        oldName: 'bp-files'
    },
    {
        newName: 'documents',
        oldName: 'bp-requirements'
    },
    {
        newName: 'documents',
        oldName: 'bp-howto'
    },
    {
        newName: 'documents',
        oldName: 'bp-jira'
    },
    {
        newName: 'documents',
        oldName: 'bp-meeting'
    },
    {
        newName: 'documents',
        oldName: 'bp-retrospective'
    },
    {
        newName: 'documents',
        oldName: 'bp-sharedlinks'
    },
    {
        newName: 'documents',
        oldName: 'bp-troubleshooting'
    },
    {
        newName: 'upload',
        oldName: 'deploy'
    },
    {
        newName: 'file',
        oldName: 'page-default'
    },
    {
        newName: 'shortcut',
        oldName: 'sidebar-link'
    },
    {
        newName: 'shortcut',
        oldName: 'sidebar-link-large'
    },
    {
        newName: 'incomplete-build',
        oldName: 'devtools-task-cancelled'
    },
    {
        newName: 'plan-disabled',
        oldName: 'devtools-task-disabled'
    },
    {
        newName: 'queued-build',
        oldName: 'devtools-task-in-progress'
    },
    {
        newName: 'branch',
        oldName: 'devtools-branch'
    },
    {
        newName: 'branch',
        oldName: 'devtools-branch-small'
    },
    {
        newName: 'commits',
        oldName: 'devtools-commit'
    },
    {
        newName: 'create-fork',
        oldName: 'devtools-for'
    },
    {
        newName: 'bold',
        oldName: 'editor-bold'
    },
    {
        newName: 'italic',
        oldName: 'editor-italic'
    },
    {
        newName: 'underline',
        oldName: 'editor-underline'
    },
    {
        newName: 'text-color',
        oldName: 'editor-color'
    },
    {
        newName: 'left-alignment',
        oldName: 'editor-align-left'
    },
    {
        newName: 'right-alignment',
        oldName: 'editor-align-right'
    },
    {
        newName: 'center-alignment',
        oldName: 'editor-align-center'
    },
    {
        newName: 'indent-left-mall',
        oldName: 'editor-indent'
    },
    {
        newName: 'indent-right-mall',
        oldName: 'editor-outdent'
    },
    {
        newName: 'number-list-mall',
        oldName: 'editor-list-number'
    },
    {
        newName: 'bullet-list-mall',
        oldName: 'editor-list-bullet'
    },
    {
        newName: 'mention',
        oldName: 'editor-mention'
    },
    {
        newName: 'table-of-contents-mall',
        oldName: 'editor-macro-toc'
    },
    {
        newName: 'advanced',
        oldName: 'editor-style'
    },
    {
        newName: 'symbol',
        oldName: 'editor-symbol'
    },
    {
        newName: 'horizontal-rule',
        oldName: 'editor-hr'
    },
    {
        newName: 'page-layout-toggle',
        oldName: 'editor-layout'
    },
    {
        newName: 'table',
        oldName: 'editor-table'
    },
    {
        newName: 'location',
        oldName: 'nav-children-large'
    },
    {
        newName: 'location',
        oldName: 'nav-children'
    },
    {
        newName: 'single-column',
        oldName: 'layout-1col-large'
    },
    {
        newName: 'two-column',
        oldName: 'layout-2col-large'
    },
    {
        newName: 'right-side-bar',
        oldName: 'layout-2col-left-large'
    },
    {
        newName: 'left-side-bar',
        oldName: 'layout-2col-right-large'
    },
    {
        newName: 'three-column-side-bars',
        oldName: 'layout-3col-center-large'
    },
    {
        newName: 'three-column',
        oldName: 'layout-3col-large'
    },
    {
        newName: 'heading-column',
        oldName: 'table-header-column'
    },
    {
        newName: 'heading-row',
        oldName: 'table-header-row'
    },
    {
        newName: 'insert-row-after',
        oldName: 'table-row-down'
    },
    {
        newName: 'insert-row-before',
        oldName: 'table-row-up'
    },
    {
        newName: 'remove-row',
        oldName: 'table-row-remove'
    },
    {
        newName: 'remove-column',
        oldName: 'table-col-remove'
    },
    {
        newName: 'insert-column-before',
        oldName: 'table-col-left'
    },
    {
        newName: 'insert-column-after',
        oldName: 'table-col-right'
    },
    {
        newName: 'remove-table',
        oldName: 'table-remove'
    },
    {
        newName: 'merge-table-cells',
        oldName: 'table-merge'
    },
    {
        newName: 'split-merged-table-cells',
        oldName: 'table-split'
    },
    {
        newName: 'copy-table-row',
        oldName: 'table-copy-row'
    },
    {
        newName: 'paste-table-row',
        oldName: 'table-paste-row'
    },
    {
        newName: 'cut-table-row',
        oldName: 'table-cut-row'
    },
    {
        newName: 'team-calendar',
        oldName: 'teamcals-large'
    },
    {
        newName: 'team-calendar',
        oldName: 'teamcals'
    },
    {
        newName: 'emoji',
        oldName: 'editor-emoticon'
    },
    {
        newName: 'help',
        oldName: 'editor-help'
    },
    {
        newName: 'task',
        oldName: 'editor-task'
    },
    {
        newName: 'like',
        oldName: 'like-small'
    },
    {
        newName: 'submodule',
        oldName: 'devtools-submodule'
    }
];

export default deprecatedIcons;
