import { css } from './aui/internal/deprecation';
import deprecatedAdg2Icons from './aui/internal/deprecation/deprecated-adg2-icons';

css('.aui-badge', {
    displayName: 'AUI Badges class'
});
css('.aui-message span.aui-icon', {
    displayName: 'Message icon span'
});
css('.aui-tabs.vertical-tabs', {
    displayName: 'Vertical tabs'
});
css('form.aui span.content');
css([
    'form.aui .button',
    'form.aui .buttons-container'
], {
    displayName: 'Unprefixed buttons',
    alternativeName: 'aui-button and aui-buttons'
});
css([
    'form.aui .icon-date',
    'form.aui .icon-range',
    'form.aui .icon-help',
    'form.aui .icon-required',
    'form.aui .icon-inline-help',
    'form.aui .icon-users',
    '.aui-icon-date',
    '.aui-icon-range',
    '.aui-icon-help',
    '.aui-icon-required',
    '.aui-icon-users',
    '.aui-icon-inline-help'
], {
    displayName: 'Form icons'
});
css([
    '.aui-dropdown2 .active',
    '.aui-dropdown2 .checked',
    '.aui-dropdown2 .disabled',
    '.aui-dropdown2 .interactive'
], {
    displayName: 'Unprefixed dropdown2 css AUI-2150'
});



// 5.9.0
// -----

var fiveNineZero = {
    // Inline Dialog
    'arrow': 'aui-inline-dialog-arrow',
    'contents': 'aui-inline-dialog-contents',
};
var name;

for (name in fiveNineZero) {
    if (Object.hasOwnProperty.call(fiveNineZero, name)) {
        css(name, {
            alternativeName: fiveNineZero[name],
            removeVersion: '8.0.0',
            sinceVersion: '5.9.0'
        });
    }
}

// 6.1.0
// -----

css([
    '.aui-header-logo-atlassian',
    '.aui-header-logo-aui',
    '.aui-header-logo-bamboo',
    '.aui-header-logo-bitbucket',
    '.aui-header-logo-stash',
    '.aui-header-logo-clover',
    '.aui-header-logo-confluence',
    '.aui-header-logo-crowd',
    '.aui-header-logo-crucible',
    '.aui-header-logo-fecru',
    '.aui-header-logo-fisheye',
    '.aui-header-logo-hipchat',
    '.aui-header-logo-jira',
    '.aui-header-logo-jira-core',
    '.aui-header-logo-jira-software',
    '.aui-header-logo-jira-service-desk',
    '.aui-header-logo-answer',
    '.aui-header-logo-community',
    '.aui-header-logo-developers',
    '.aui-header-logo-expert',
    '.aui-header-logo-partner-program',
    '.aui-header-logo-marketplace',
    '.aui-header-logo-support',
    '.aui-header-logo-university',
    '.aui-header-logo-cloud',
], {
    displayName: 'Atlassian Brand Logos',
    sinceVersion: '6.1.0',
    removeInVersion: '9.0.0',
    extraInfo: 'The Atlassian logos were extracted to their own Node package. See https://www.npmjs.com/package/@atlassian/brand-logos'
});

// 7.1.0
// -----

css('.aui-badge', {
    displayName: 'AUI Badge CSS class',
    alternativeName: 'aui-badge',
    sinceVersion: '7.1.0',
    extraInfo: 'The badge pattern is best used as a web component instead of a CSS class'
});

// 7.5.0
// -----

css([
    '.aui-iconfont-image-extrasmall',
], {
    displayName: 'Special size icon names',
    sinceVersion: '7.5.0',
    extraInfo: 'The only size variant allowed for icon names is `-small`.'
});

// New ADGS names for the old ADG2 icon
deprecatedAdg2Icons.forEach(({ newName, oldName }) => (
    css(`.aui-iconfont-${oldName}`, {
        displayName: 'ADG2 icon',
        alternativeName: `.aui-iconfont-${newName}`,
        sinceVersion: '7.5.0',
        removeVersion: '8.0.0',
        extraInfo: 'Use the new ADGS icon CSS class name'
    })
));

// 7.8.0
css('.aui-table-interactive', {
    alternativeName: '.aui-table-list',
    sinceVersion: '7.8.0',
    removeInVersion: '9.0.0',
    extraInfo: 'The "interactive" suffix caused some confusion when contrasted with sortable tables.' +
    'The name has been updated to reflect its intended purpose: displaying lists of data in a tabular format.'
});

// 7.9.4
css('aui-spinner[filled]', {
    sinceVersion: '7.9.4',
    removeInVersion: '9.0.0',
    extraInfo: `Add CSS to the parent element of the <aui-spinner>.
Use CSS flexbox or grid to vertically align it.
See https://css-tricks.com/centering-css-complete-guide/ for techniques.`
});

//8.4.0

css('.aui-button-light', {
    sinceVersion: '8.4.0',
    removeInVersion: '9.0.0',
    extraInfo: 'ADGS allows only for normal and subtle buttons.'
});
